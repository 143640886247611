body {
  background: rgb(7, 0, 124);
  /* background: radial-gradient(
    circle,
    rgba(7, 0, 124, 1) 0%,
    rgba(2, 0, 36, 1) 100%
  ); */
  background: radial-gradient(  #004e92 0%, #000428 90% );
  color: white;
  margin: 0;
  padding: 0;
  
  font-family: 'Roboto', sans-serif;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

header {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
}

header > div {
  position:relative;
  z-index: 100;
  backdrop-filter: blur(4px);
  box-shadow: 0 0 7px #ffffff6e;
  box-sizing: border-box;
  text-shadow: 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2);


  padding: 3rem 5rem;
  border-radius: 2rem;
}

header h1 {
  text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
  font-size: 3rem;
  margin: 0;
}

header h2 {
  font-size: 1.6rem;
  margin: 1rem 0 0 0;
}

header p {
  font-size: 1.2rem;
  margin: 0.4rem 0 0 0;
}

header a {
  position: relative;
  z-index: 100;
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  header > div {
    border-radius: 0;
    width: 100%;
    padding: 3rem 2rem;
  }

  header h1 {
    font-size: 2.4rem;
  }

  header h2 {
    font-size: 1.4rem;
  }

  header p {
    font-size: 1rem;
  }
}

.links {
  margin-top: 2rem;
}

.link img {
  width: 4rem;
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0,0,0,.15));
  filter: drop-shadow(5px 5px 5px rgba(0,0,0,.15));

  transition: all .1s ease-in-out;
}

.link img:hover {
  transform: scale(1.02) translateY(-3px);
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0,0,0,.25));
  filter: drop-shadow(5px 5px 5px rgba(0,0,0,.25));
}
