body {
  color: #fff;
  background: radial-gradient(#004e92 0%, #000428 90%);
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

header {
  width: 100%;
  height: 100vh;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

header > div {
  z-index: 100;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-sizing: border-box;
  text-shadow: 0 3px 5px #0003, 0 5px 10px #00000040, 0 10px 10px #0003;
  border-radius: 2rem;
  padding: 3rem 5rem;
  position: relative;
  box-shadow: 0 0 7px #ffffff6e;
}

header h1 {
  text-shadow: 0 1px #ccc, 0 2px #c9c9c9, 0 1px 3px #0000004d, 0 3px 5px #0003, 0 5px 10px #00000040, 0 10px 10px #0003, 0 20px 20px #00000026;
  margin: 0;
  font-size: 3rem;
}

header h2 {
  margin: 1rem 0 0;
  font-size: 1.6rem;
}

header p {
  margin: .4rem 0 0;
  font-size: 1.2rem;
}

header a {
  z-index: 100;
  color: #fff;
  text-decoration: none;
  position: relative;
}

@media (width <= 768px) {
  header > div {
    width: 100%;
    border-radius: 0;
    padding: 3rem 2rem;
  }

  header h1 {
    font-size: 2.4rem;
  }

  header h2 {
    font-size: 1.4rem;
  }

  header p {
    font-size: 1rem;
  }
}

.links {
  margin-top: 2rem;
}

.link img {
  width: 4rem;
  -webkit-filter: drop-shadow(5px 5px 5px #00000026);
  filter: drop-shadow(5px 5px 5px #00000026);
  transition: all .1s ease-in-out;
}

.link img:hover {
  -webkit-filter: drop-shadow(5px 5px 5px #00000040);
  filter: drop-shadow(5px 5px 5px #00000040);
  transform: scale(1.02)translateY(-3px);
}

/*# sourceMappingURL=index.a1222ba1.css.map */
